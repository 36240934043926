import React, { useState } from 'react';
import { Popover, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './RsaOption.module.scss';
import ConfirmationBox from '../../../components/ConfirmationBox/index';
import { unlockAccount } from '../../../redux/Rsa/Slice';
import { useNavigate } from 'react-router-dom';
import { showSuccessNotification } from '../../../redux/Notification';

export default function RsaOptionMenus() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openUnlockConfBox, setOpenUnlockConfBox] = useState(false);
    const [openResetRSAPinConfBox, setOpenResetRSAPinConfBox] = useState(false);
    const [openOTTConfBox, setOpenOTTConfBox] = useState(false);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }
    function unlockRSAAccount() {
        setOpenUnlockConfBox(true);
    }
    function handleUnlockCancel() {
        setOpenUnlockConfBox(false);
    }
    function handleUnlockAccount() {
        dispatch(unlockAccount());
        dispatch(showSuccessNotification("Unlocking your RSA account.."));
        setAnchorEl(null);
        setOpenUnlockConfBox(false);
    }

    function resetRSAPin() {
        setOpenResetRSAPinConfBox(true);
    }
    function handleResetRSAPinCancel() {
        setOpenResetRSAPinConfBox(false);
    }
    function handleResetRSAPinProceed() {
        navigate(`/rsa/setnewpin`);
    }

    function oneTimeToken() {
        setOpenOTTConfBox(true);
    }
    function handleOTTCancel() {
        setOpenOTTConfBox(false);
    }
    function handleOTTProceed() {
        setOpenOTTConfBox(false);
        setAnchorEl(null);
        navigate(`/rsa/ott`);
    }

    return (
        <>
            <ConfirmationBox data-testid="unlock-conf"
                openConfirmationBox={openUnlockConfBox}
                handleConfirmationBoxCancel={handleUnlockCancel}
                handleProceed={handleUnlockAccount}
                dialogContentText={"You are about to unlock your RSA Account. Are you Sure want to continue?"}
            />

            <ConfirmationBox data-testid="reset-pin-conf"
                openConfirmationBox={openResetRSAPinConfBox}
                handleConfirmationBoxCancel={handleResetRSAPinCancel}
                handleProceed={handleResetRSAPinProceed}
                dialogContentText={"Proceeding with this action will reset your RSA PIN. Are you Sure want to continue?"}
            />

            <ConfirmationBox data-testid="ott-conf"
                openConfirmationBox={openOTTConfBox}
                handleConfirmationBoxCancel={handleOTTCancel}
                handleProceed={handleOTTProceed}
                dialogContentText={"Proceeding with this action will generate One-time Token. Are you Sure want to continue?"}
            />

            <div>
                <Button
                    id="rsa-option-button"
                    aria-controls={open ? 'rsa-option-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className={styles['rsa-option-button']}
                >
                    Options
                </Button>
                <Popover
                    id='rsa-menu'
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => handleClose()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={styles['rsa-option-container']}>
                        <div className={styles['rsa-option-menu']} onClick={() => unlockRSAAccount()}>Unlock RSA</div>
                        <div className={styles['rsa-option-menu']} onClick={() => resetRSAPin()}>Reset RSA Pin</div>
                    </div>
                </Popover>
            </div>
        </>
    );
}
