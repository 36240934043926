import * as Api from '../../api';
import { createSlice } from '@reduxjs/toolkit';
import { showLoader, hideLoader } from '../Loader';
import { showErrorNotification, showSuccessNotification } from '../Notification';
import { STATUS_PROCESSING } from '../../constants';
import { getItem } from '../../util/localStorage';
import moment from 'moment';

export const initialState = {
    activeItem: 0,
    isAuthenticated: null,
    isLogIn: false,
    unlockADAccountStatus: "",
};

export const selfServiceSlice = createSlice({
    name: 'selfServiceDetails',
    initialState,
    reducers: {
        setAuthenticationStatus: (state, { payload }) => {
            return {
                ...state,
                isAuthenticated: payload,
            }
        },
        setLogIn: (state, { payload }) => {
            return {
                ...state,
                isLogIn: payload,
            }
        },
        setUnlockADAccountStatus: (state, action) => {
            return {
                ...state,
                unlockADAccountStatus: action.payload,
            }
        },
    },
})

export const { setAuthenticationStatus, setLogIn, setUnlockADAccountStatus } = selfServiceSlice.actions

export const selfServiceSelector = (state) => state.selfServiceDetails;

export default selfServiceSlice.reducer;

export const validateToken = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Get(dispatch, 'api/v1/userProfile/authenticationStatus', null, null)
                .then(data => {
                    if (data.toLowerCase() === "authenticated") {
                        dispatch(setAuthenticationStatus(true));
                        dispatch(hideLoader());
                    }
                    else {
                        window.localStorage.removeItem('isActive');
                        dispatch(setAuthenticationStatus(false));
                        clearDataFromLocalStorage();
                        dispatch(hideLoader());
                    }
                })
                .catch((error) => {
                    window.localStorage.removeItem('isActive');
                    dispatch(setAuthenticationStatus(false));
                    clearDataFromLocalStorage();
                    dispatch(hideLoader());
                });
        });
    };
}

export const validateTokenAndRedirect = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Get(dispatch, 'api/v1/userProfile/authenticationStatus', null, null)
                .then(data => {
                    if (data.toLowerCase() === "authenticated") {
                        dispatch(setAuthenticationStatus(true));
                        window.location.href = ("/apps")
                    }
                    else {
                        window.localStorage.removeItem('isActive');
                        dispatch(setAuthenticationStatus(false));
                        clearDataFromLocalStorage();
                        window.location.href = ("/")
                    }
                })
                .catch((error) => {
                    window.localStorage.removeItem('isActive');
                    dispatch(setAuthenticationStatus(false));
                    clearDataFromLocalStorage();
                    window.location.href = ("/")
                });
        });
    };
}

export const inValidateToken = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(setAuthenticationStatus(null));
        });
    };
}

export const logOff = (event) => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Post(dispatch, 'api/v1/userProfile/logout', null, null)
                .then(data => {
                    dispatch(setLogIn(false));
                    dispatch(inValidateToken());
                    clearDataFromLocalStorage();
                    event !== true ? window.location.assign('/?logout') : window.location.assign('/true');
                })
                .catch((error) => {
                    dispatch(setLogIn(false));
                    dispatch(inValidateToken());
                    dispatch(hideLoader());
                    clearDataFromLocalStorage();
                    event !== true ? window.location.assign('/?logout') : window.location.assign('/true');
                });

        });
    };
}

export const clearTokenStatus = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(setAuthenticationStatus(null));
        });
    }
}

export const runOktaAuthentication = (userEmail) => {
    return async (dispatch) => {
        return new Promise(() => {
            Api.Get(dispatch, `api/v1/user/redirectUrl?userEmail=${userEmail}`, null, null)
                .then(data => {
                    window.location.href = data;
                    dispatch(hideLoader());
                })
                .catch((error) => {
                    dispatch(hideLoader());
                    dispatch(showErrorNotification(error.message));
                });
        });
    };
}

export const getJWTToken = (state, code) => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Post(dispatch, 'api/v1/user/login', null, ({ 'state': state, 'code': code }))
                .then(data => {
                    dispatch(showLoader());
                    window.localStorage.setItem('isActive', true);
                    window.localStorage.setItem('csrf_token_id', data);
                    window.localStorage.setItem('lTime', new moment().add(15, 'minutes'));
                    dispatch(setLogIn(true));
                    
                    setTimeout(function () {
                        window.location.href = '/apps';
                    }, 2000);
                })
                .catch((error) => {
                    dispatch(hideLoader());
                    dispatch(showErrorNotification(error));
                    dispatch(setLogIn(false));
                });
        });
    };
}

export const unlockADAccount = () => {
    return async (dispatch) => {
        return new Promise(() => {
            dispatch(showLoader());
            Api.Post(dispatch, 'api/v1/userProfile/unlock', null, null)
                .then(data => {
                    dispatch(setUnlockADAccountStatus(data));
                    dispatch(showSuccessNotification("Your AD-ENT account has been successfully unlocked."));
                    dispatch(hideLoader());
                })
                .catch((error) => {
                    dispatch(hideLoader());
                    dispatch(showErrorNotification(error.message));
                });

        });
    };
}

function clearDataFromLocalStorage() {
    window.localStorage.removeItem('isActive');
    window.localStorage.removeItem('empId');
    window.localStorage.removeItem('userLastName');
    window.localStorage.removeItem('userFirstName');
    window.localStorage.removeItem('uId');
    window.localStorage.removeItem('uType');
}
